import React, { useMemo } from "react";
import {
  CREATE_FORMULA_RECIPE_LIST,
  DELETE_FORMULA_RECIPE_LIST,
  UPDATE_FORMULA_RECIPE_LIST,
} from "../../../actions/FormulaRecipeAction";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useFieldArray, useForm } from "react-hook-form";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import SearchInput from "../../common/searchInput";
import { GOODS_AND_SERVICE_LIST } from "../../../actions/BarangAction";

const AddNewFormulaRecipe = (props) => {
  const {
    tabOpen,
    setTabOpen,
    detail,
    tabs,
    setTabs,
    dataPerTabs,
    setChoosenData,
    choosenData,
    refetch,
  } = props;

  const validationSchema = yup.object().shape({
    kode_barang: yup.string().required("Pilih salah satu barang persediaan"),
    uom_id: yup.string().required("Satuan wajib diisi"),
    pcs: yup
      .number()
      .typeError("Qty harus berupa angka")
      .positive("Qty harus lebih dari 0")
      .required("Qty wajib diisi"),
    detail_resep: yup
      .array()
      .of(
        yup.object().shape({
          detail_resep_id: yup.number().nullable(),
          kode_barang: yup.string().required("Bahan wajib diisi"),
          pcs: yup
            .number()
            .typeError("Qty harus berupa angka")
            .positive("Qty harus lebih dari 0")
            .required("Qty wajib diisi"),
          uom_id: yup.string().required("Satuan wajib diisi"),
          mode: yup.string().nullable(),
          alternatif: yup
            .array()
            .of(
              yup.object().shape({
                detail_resep_id: yup.string().nullable(),
                kode_barang: yup
                  .string()
                  .required("Alternatif bahan wajib diisi"),
                pcs: yup
                  .number()
                  .typeError("Qty harus berupa angka")
                  .positive("Qty harus lebih dari 0")
                  .required("Qty wajib diisi"),
                uom_id: yup.string().required("Satuan wajib diisi"),
                mode: yup.string().nullable(),
              })
            )
            .default([]),
        })
      )
      .min(1, "Harus tentukan minimal satu bahan"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      pcs: "1",
      detail_resep: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "detail_resep",
    control,
  });

  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const requiredItems = Object.values(errors);

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
  }, [errors]);

  const handleUpdateTabData = () => {
    const updatedList = dataPerTabs.filter(
      (tab) => tab.idTab !== choosenData.idTab
    );
    setChoosenData(updatedList);
  };

  const { data: dataBarangJasa } = useQuery(GOODS_AND_SERVICE_LIST);
  const [createFormulaRecipe, { loading }] = useMutation(
    CREATE_FORMULA_RECIPE_LIST,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses menambahkan data");
        refetch({
          variables: {
            input: {
              nama: "",
            },
          },
        });
      },
      onError: (resp) => {
        toast.error(resp.message);
      },
      // update(cache, { data }) {
      //   const list = cache.readQuery({
      //     query: SATUAN_LIST,
      //     variables: {
      //       input: {
      //         nama: "",
      //       },
      //     },
      //   });
      //   cache.writeQuery({
      //     query: SATUAN_LIST,
      //     variables: {
      //       input: {
      //         nama: "",
      //       },
      //     },
      //     data: {
      //       barang: {
      //         getFormulaAddNewFormulaRecipeBarang: {
      //           data: [
      //             ...list.barang.getFormulaAddNewFormulaRecipeBarang.data,
      //             data.barang.createFormulaAddNewFormulaRecipeBarang,
      //           ],
      //         },
      //       },
      //     },
      //   });
      // },
    }
  );

  const [deleteFormulaRecipe] = useMutation(DELETE_FORMULA_RECIPE_LIST, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch({
        variables: {
          input: {
            nama: "",
          },
        },
      });
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: SATUAN_LIST,
    //     variables: {
    //       input: {
    //         nama: "",
    //       },
    //     },
    //   });
    //   const updatedList = list.barang.getFormulaAddNewFormulaRecipeBarang.data.filter(
    //     (uom) => uom.uom_id !== choosenData.uom_id
    //   );
    //   cache.writeQuery({
    //     query: SATUAN_LIST,
    //     variables: {
    //       input: {
    //         nama: "",
    //       },
    //     },
    //     data: {
    //       barang: {
    //         getFormulaAddNewFormulaRecipeBarang: {
    //           data: updatedList,
    //         },
    //       },
    //     },
    //   });
    // },
  });

  const [ubahFormulaRecipe, { loading: loadingUpdate }] = useMutation(
    UPDATE_FORMULA_RECIPE_LIST,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch({
          variables: {
            input: {
              nama: "",
            },
          },
        });
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const barangJasaList = dataBarangJasa?.barang.findAll.data.map((barang) => ({
    id: barang.kode_barang,
    name: barang.nama_barang,
    ...barang,
  }));
  const persediaanBarangList = barangJasaList?.filter(
    (barang) => barang.is_bahan_produksi
  );
  const satuanUtamaList = useMemo(() => {
    if (watch("kode_barang") !== undefined && persediaanBarangList) {
      const choosenBarang = persediaanBarangList.find(
        (barang) => barang.id === watch("kode_barang")
      );
      const getSatuanPerBarang = choosenBarang.detail_harga.map(
        (item) => item.uom
      );

      getSatuanPerBarang.push({
        uom_alias: choosenBarang.base_uom.uom_alias,
        uom_id: choosenBarang.base_uom.uom_id,
        uom_name: choosenBarang.base_uom.uom_name,
      });

      const satuanBarang = getSatuanPerBarang.map((uom) => ({
        id: uom.uom_id,
        name: `${uom.uom_alias} - ${uom.uom_name}`,
        alias: uom.uom_name,
      }));
      return satuanBarang;
    } else {
      return [];
    }
  }, [watch("kode_barang"), persediaanBarangList]);

  React.useEffect(() => {
    if (detail) {
      setValue("kode_barang", choosenData.kode_barang);
      setValue("uom_id", choosenData.satuan.uom_id);
      setValue("pcs", choosenData.qty);
      setValue(
        "detail_resep",
        choosenData.detail.map((item) => ({
          detail_resep_id: item.detail_resep_id,
          kode_barang: item.kode_barang,
          pcs: item.qty.toString(),
          uom_id: item.satuan.uom_id,
          mode: "e",
          alternatif: item.alternatif.map((itemAlt) => ({
            detail_resep_id: itemAlt.alternatif_resep_id,
            kode_barang: itemAlt.kode_barang,
            pcs: itemAlt.qty.toString(),
            uom_id: itemAlt.satuan.uom_id,
            mode: "e",
          })),
        }))
      );
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const onSubmit = (data) => {
    const inputs = {
      kode_barang: data.kode_barang,
      uom_id: parseFloat(data.uom_id),
      pcs: parseFloat(data.pcs),
      detail_resep: (detail
        ? data.detail_resep
        : data.detail_resep.filter((detail) => detail.mode === "c")
      ).map((ingredient) => ({
        detail_resep_id: detail
          ? ingredient.detail_resep_id === "0"
            ? null
            : parseFloat(ingredient.detail_resep_id)
          : null,
        kode_barang: ingredient.kode_barang,
        pcs: parseFloat(ingredient.pcs),
        uom_id: parseFloat(ingredient.uom_id),
        mode: ingredient.mode,
        alternatif: (detail
          ? ingredient.alternatif
          : ingredient.alternatif.filter((detail) => detail.mode === "c")
        ).map((alts) => ({
          detail_resep_id: detail
            ? alts.detail_resep_id === "0"
              ? null
              : parseFloat(alts.detail_resep_id)
            : null,
          kode_barang: alts.kode_barang,
          pcs: parseFloat(alts.pcs),
          uom_id: parseFloat(alts.uom_id),
          mode: alts.mode,
        })),
      })),
    };
    if (detail) {
      ubahFormulaRecipe({
        variables: {
          input: {
            id: choosenData.resep_id,
            ...inputs,
          },
        },
      });
    } else {
      createFormulaRecipe({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteFormulaRecipe({
      variables: {
        input: choosenData.resep_id,
      },
    });
  };

  const appendAlternative = (ingredientIndex) => {
    const currentAlternatives =
      watch(`detail_resep.${ingredientIndex}.alternatif`)?.filter(
        (alt) => alt.mode !== "d"
      ) || [];
    setValue(`detail_resep.${ingredientIndex}.alternatif`, [
      ...currentAlternatives,
      { detail_resep_id: 0, kode_barang: "", pcs: "1", uom_id: "", mode: "c" },
    ]);
  };

  const removeAlternative = (ingredientIndex, altIndex) => {
    const currentAlternatives =
      watch(`detail_resep.${ingredientIndex}.alternatif`) || [];

    if (currentAlternatives[altIndex]?.mode === "c") {
      const updatedAlternatives = currentAlternatives.filter(
        (_, index) => index !== altIndex
      );
      setValue(
        `detail_resep.${ingredientIndex}.alternatif`,
        updatedAlternatives
      );
    } else {
      setValue(
        `detail_resep.${ingredientIndex}.alternatif.${altIndex}.mode`,
        "d"
      );
    }
  };

  const removeItem = (index) => {
    setValue(`detail_resep.${index}.mode`, "d");
    remove(index);
  };

  return (
    <div>
      <div className="left-content-module">
        <div className="main-content-module detail-page full-tab">
          <div class="flex-center">
            <div class="tab-list active">Formulasi/Resep</div>
          </div>
          <div className="detail-data-wrap">
            <div
              className="content-wrap"
              style={{ height: "calc(100vh - 170px)", overflow: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-wrap">
                  <SearchInput
                    title={"Barang Persediaan"}
                    placeholder="Cari bahan"
                    value={watch("kode_barang")}
                    setValue={(e) => {
                      if (e === null) {
                        setValue("kode_barang", "");
                        setValue("pcs", 1);
                      } else {
                        setValue("kode_barang", e ? e : "");
                      }
                    }}
                    searchData={persediaanBarangList}
                  />

                  <div class="form-container md">
                    <div class="title">Qty</div>

                    <div class="auto-form-container">
                      <input type="text" {...register("pcs")} />
                    </div>
                  </div>

                  <SearchInput
                    className="sm padding-l"
                    title="Satuan"
                    placeholder="Cari satuan"
                    value={watch("uom_id")}
                    setValue={(e) => setValue("uom_id", e)}
                    searchData={satuanUtamaList}
                  />

                  {fields
                    .filter((item) => item.mode !== "d")
                    .map((item, index) => {
                      return (
                        <>
                          <BahanInputan
                            key={index}
                            watch={watch}
                            appendAlternative={appendAlternative}
                            barangOption={barangJasaList}
                            index={index}
                            isAlternative={false}
                            register={register}
                            remove={remove}
                            setValue={setValue}
                            removeItem={removeItem}
                          />

                          <div class="main-alternatif-wrap">
                            {watch(`detail_resep.${index}.alternatif`, [])
                              ?.filter((alt) => alt.mode !== "d")
                              ?.map((alt, altIndex) => (
                                <BahanInputan
                                  key={index}
                                  watch={watch}
                                  appendAlternative={appendAlternative}
                                  barangOption={barangJasaList}
                                  index={index}
                                  isAlternative={true}
                                  register={register}
                                  remove={remove}
                                  setValue={setValue}
                                  altIndex={altIndex}
                                  removeAlternative={removeAlternative}
                                />
                              ))}
                          </div>
                        </>
                      );
                    })}

                  <div class="form-container">
                    <div
                      class="button button-center btn-blue"
                      onClick={() =>
                        append({
                          detail_resep_id: 0,
                          kode_barang: "",
                          pcs: "1",
                          uom_id: "",
                          mode: "c",
                          alternatif: [],
                        })
                      }
                    >
                      Tambah Bahan
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!--CONTENT RIGHT--> */}
      <div className="right-content-module">
        <div className="data-action-wrap">
          <div
            className={`data-action-button green-bg ${
              (loading || loadingUpdate) && "disabled"
            }`}
            onClick={handleSubmit(onSubmit)}
          >
            <span className="icon icon-simpan_ic"></span>
            Simpan
          </div>

          {detail && (
            <div
              className="data-action-button red-bg"
              onClick={() => setOpenDelete(true)}
            >
              <span className="icon icon-hapus_ic"></span>
              Hapus
            </div>
          )}
        </div>
      </div>
      <div className="clear"></div>

      <ModalConfirm show={openDelete}>
        <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
        <ModalBody>
          <p>
            Apakah Anda yakin akan melakukan penghapusan data: Formula/Resep{" "}
            {choosenData?.uom_name}?
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="button btn-red flex-center" onClick={confirmDelete}>
            Hapus
          </div>
          <div
            className="button btn-transparent flex-center"
            onClick={() => setOpenDelete(false)}
          >
            Batal
          </div>
        </ModalFooter>
      </ModalConfirm>

      <ModalConfirm show={openError}>
        <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
        <ModalBody>
          <p>
            Masih memerlukan inputan ini :
            <ul>
              {errors.nama_resep && <li>{errors.nama_resep.message}</li>}

              {errors.detail_resep?.message && (
                <li>{errors.detail_resep.message}</li>
              )}
              {errors.detail_resep?.map((error, index) => (
                <li key={index}>
                  <p>Bahan ke-{index + 1}:</p>
                  {error?.kode_barang && <p>- {error.kode_barang.message}</p>}
                  {error?.pcs && <p>- {error.pcs.message}</p>}
                  {error?.uom_id && <p>- {error.uom_id.message}</p>}

                  {error?.alternatif?.map((altError, altIndex) => (
                    <ul key={altIndex}>
                      <li>
                        Alternatif ke-{altIndex + 1}:
                        {altError?.kode_barang && (
                          <p>- {altError.kode_barang.message}</p>
                        )}
                        {altError?.pcs && <p>- {altError.pcs.message}</p>}
                        {altError?.uom_id && <p>- {altError.uom_id.message}</p>}
                      </li>
                    </ul>
                  ))}
                </li>
              ))}
            </ul>
          </p>
        </ModalBody>
        <ModalFooter>
          <div
            className="button btn-transparent flex-center"
            onClick={() => setOpenError(false)}
          >
            Tutup
          </div>
        </ModalFooter>
      </ModalConfirm>
    </div>
  );
};

export default AddNewFormulaRecipe;

const BahanInputan = ({
  watch,
  setValue,
  register,
  index,
  isAlternative,
  barangOption,
  appendAlternative,
  altIndex,
  removeAlternative,
  removeItem,
}) => {
  const barangOptions = barangOption?.filter((barang) => barang.is_bahan_baku);

  // const detail = watch(
  //   isAlternative
  //     ? `detail_resep.${index}.alternatif.${altIndex}.detail_resep_id`
  //     : `detail_resep.${index}.detail_resep_id`
  // );
  const bahanId = watch(
    isAlternative
      ? `detail_resep.${index}.alternatif.${altIndex}.kode_barang`
      : `detail_resep.${index}.kode_barang`
  );
  const satuanList = useMemo(() => {
    if (bahanId !== "" && barangOptions) {
      const choosenBarang = barangOptions.find(
        (barang) => barang.id === bahanId
      );
      const getSatuanPerBarang = choosenBarang.detail_harga.map(
        (item) => item.uom
      );

      getSatuanPerBarang.push({
        uom_alias: choosenBarang.base_uom.uom_alias,
        uom_id: choosenBarang.base_uom.uom_id,
        uom_name: choosenBarang.base_uom.uom_name,
      });

      const satuanBarang = getSatuanPerBarang.map((uom) => ({
        id: uom.uom_id,
        name: `${uom.uom_alias} - ${uom.uom_name}`,
        alias: uom.uom_name,
      }));
      return satuanBarang;
    } else {
      return [];
    }
  }, [bahanId, barangOptions]);

  return (
    <div
      key={index}
      class={
        isAlternative
          ? "flex-between bahan-wrap alternatif"
          : "flex-between bahan-wrap"
      }
    >
      <SearchInput
        title={
          isAlternative
            ? `Bahan ${index + 1} Alternatif ${altIndex + 1}`
            : `Bahan ${index + 1}`
        }
        placeholder="Cari bahan"
        value={watch(
          isAlternative
            ? `detail_resep.${index}.alternatif.${altIndex}.kode_barang`
            : `detail_resep.${index}.kode_barang`
        )}
        setValue={(e) => {
          if (e === null) {
            setValue(
              isAlternative
                ? `detail_resep.${index}.alternatif.${altIndex}.uom_id`
                : `detail_resep.${index}.uom_id`,
              ""
            );
            setValue(
              isAlternative
                ? `detail_resep.${index}.alternatif.${altIndex}.pcs`
                : `detail_resep.${index}.pcs`,
              1
            );
          } else {
            setValue(
              isAlternative
                ? `detail_resep.${index}.alternatif.${altIndex}.kode_barang`
                : `detail_resep.${index}.kode_barang`,
              e ? e : ""
            );
          }
        }}
        searchData={barangOptions}
      />

      <div class="form-container form-qty">
        <div class="title">Qty</div>

        <div class="auto-form-container" style={{ width: "70px" }}>
          <input
            type="text"
            {...register(
              isAlternative
                ? `detail_resep.${index}.alternatif.${altIndex}.pcs`
                : `detail_resep.${index}.pcs`
            )}
          />
        </div>
      </div>

      <SearchInput
        title="Satuan"
        placeholder="Cari satuan"
        value={watch(
          isAlternative
            ? `detail_resep.${index}.alternatif.${altIndex}.uom_id`
            : `detail_resep.${index}.uom_id`
        )}
        setValue={(e) =>
          setValue(
            isAlternative
              ? `detail_resep.${index}.alternatif.${altIndex}.uom_id`
              : `detail_resep.${index}.uom_id`,
            e
          )
        }
        searchData={satuanList}
      />

      {!isAlternative && (
        <div class="form-action">
          <div
            class="icon-wrap icon-list_ic"
            onClick={() => appendAlternative(index)}
          ></div>
        </div>
      )}

      <div class="form-action">
        <div
          class="icon-wrap icon-hapus_ic"
          onClick={() =>
            isAlternative
              ? removeAlternative(index, altIndex)
              : removeItem(index)
          }
        ></div>
      </div>
    </div>
  );
};
