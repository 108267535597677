import React, { useEffect } from "react";

const FormulaRecipeList = (props) => {
  const {
    tabs,
    setTabs,
    setActive,
    choosenData,
    setChoosenData,
    getFormulaRecipeList,
    loading,
    dataFormulaRecipe,
  } = props;


  useEffect(() => {
    getFormulaRecipeList();
    // eslint-disable-next-line
  }, []);

  const formulaRecipeList = dataFormulaRecipe?.resep.findAll;
  const countFormulaRecipe = dataFormulaRecipe?.resep.findAll.length;

  const handleOpenDetail = (index) => {
    if (tabs.find((tab) => tab.id === formulaRecipeList[index].resep_id)) {
      setTabs([...tabs]);
    } else {
      setTabs([
        ...tabs,
        {
          label: formulaRecipeList[index].nama_resep,
          type: "detail",
          id: formulaRecipeList[index].resep_id,
        },
      ]);
    }
    setActive(formulaRecipeList[index].resep_id);
    setChoosenData([
      ...choosenData,
      { idTab: formulaRecipeList[index].resep_id, ...formulaRecipeList[index] },
    ]);
  };

  return (
    <>
      <div class="content_nav_module">
        <form id="" class="">
          <div class="form-right-side">
            {/* <div class="form-wrap m-l-10">
              <div class="form-container">
                <div class="title">Pencarian</div>
                <input
                  id=""
                  class="form-search-input"
                  type="text"
                  placeholder="Cari disini"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div class="icon-form-date icon-search_ic"></div>
              </div>
            </div> */}

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{countFormulaRecipe}</div>
            </div>
          </div>

          <div class="clear"></div>
        </form>
      </div>
      <div class="main-content-module">
        <div className="tableFixHead">
          <table id="all-data-table">
            <tr>
              <th>Nama</th>
            </tr>
            {loading ? (
              <p>Loading...</p>
            ) : countFormulaRecipe === 0 ? (
              <tr>
                <td>Tidak ada data.</td>
              </tr>
            ) : (
              formulaRecipeList?.map((resep, i) => (
                <tr onClick={() => handleOpenDetail(i)}>
                  <td>{resep.nama_resep}</td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default FormulaRecipeList;
