import { gql } from "@apollo/client";

export const FORMULA_RECIPE_FRAGMENT = gql`
  fragment FormulaRecipe on mResep {
    resep_id
    nama_resep
    kode_barang
    satuan {
      uom_id
    }
    qty
    detail {
      detail_resep_id
      kode_barang
      satuan {
        uom_id
      }
      qty
      alternatif {
        alternatif_resep_id
        kode_barang
        satuan {
          uom_id
        }
        qty
      }
    }
  }
`;

export const FORMULA_RECIPE_LIST = gql`
  query getListFormulaRecipe {
    resep {
      findAll {
        ...FormulaRecipe
      }
    }
  }
  ${FORMULA_RECIPE_FRAGMENT}
`;

export const CREATE_FORMULA_RECIPE_LIST = gql`
  mutation createFormulaRecipe($input: ResepInput!) {
    resep {
      create(input: $input) {
        ...FormulaRecipe
      }
    }
  }
  ${FORMULA_RECIPE_FRAGMENT}
`;

export const UPDATE_FORMULA_RECIPE_LIST = gql`
  mutation updateFormulaRecipe($input: EditResepInput!) {
    resep {
      update(input: $input) {
        ...FormulaRecipe
      }
    }
  }
  ${FORMULA_RECIPE_FRAGMENT}
`;

export const DELETE_FORMULA_RECIPE_LIST = gql`
  mutation deleteFormulaRecipe($input: Int!) {
    resep {
      delete(input: $input)
    }
  }
`;
