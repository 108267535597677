import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import * as Yup from "yup";
import DropdownInput from "../../common/dropdownInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchInput from "../../common/searchInput";
import { useMutation, useQuery } from "@apollo/client";
import { SATUAN_LIST } from "../../../actions/SatuanBarangAction";
import { GOODS_AND_SERVICE_LIST } from "../../../actions/BarangAction";
import ModalConfirm from "../../common/modal/modalConfirm";
import ModalHeader from "../../common/modal/modalHeader";
import ModalBody from "../../common/modal/modalBody";
import ModalFooter from "../../common/modal/modalFooter";
import {
  CREATE_TRANSFER_GOODS,
  DELETE_TRANSFER_GOODS,
  UPDATE_TRANSFER_GOODS,
} from "../../../actions/TransferGoodsAction";
import { toast } from "react-toastify";
import { GUDANG_LIST } from "../../../actions/GudangAction";
import { format } from "date-fns";
import { tipeProsesOption } from "../../data";
import { PURCHASE_ORDER_LIST } from "../../../actions/PurchaseOrderAction";
import { REQUEST_ORDER_LIST } from "../../../actions/RequestOrderAction";
import { RECEIVE_ORDER_LIST } from "../../../actions/ReceiveOrderAction";
import Status from "../../common/status";

const AddNewGoodsTransfer = (props) => {
  const {
    detail,
    choosenData,
    dataPerTabs,
    setChoosenData,
    tabs,
    setTabs,
    tabOpen,
    setTabOpen,
    refetch,
    dataGoodsTransfer,
    handleRedirectDetail,
  } = props;

  const datePickerRef = useRef(null);

  const [tab, setTab] = useState(0);
  const [modalTab, setModalTab] = useState(0);
  const [showType, setShowType] = useState(false);
  const [show, setShow] = useState({
    menu: false,
    employees: false,
    modal: false,
    ambil: false,
  });
  const [productToAdd, setProductToAdd] = useState({
    code: "",
    name: "",
    quantity: 0,
    stok: 0,
    satuan: null,
    remarks: "",
    mode: "c",
  });
  const [productPhoto, setProductPhoto] = useState(null);
  const [productList, setProductList] = useState([]);
  const [choosenId, setChoosenId] = useState(0);
  const [editProduct, setEditProduct] = useState(false);
  const [kirimBarangOption, setKirimBarangOption] = useState([]);
  const [satuanOption, setSatuanOption] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [ambilBarangOption, setAmbilBarangOption] = useState([]);

  const validationSchema = Yup.object().shape({
    tglPemindahan: Yup.string().required("Tanggal Pemindahan"),
    tipeProses: Yup.string().required("Tipe Proses"),
    gudangDari: Yup.string().when("tipeProses", {
      is: "KIRIM_BARANG",
      then: (schema) => schema.required("Gudang pengirim"),
      otherwise: (schema) => schema.nullable(),
    }),
    gudangKe: Yup.string().required("Gudang penerima"),
    noKirim: Yup.string().when("tipeProses", {
      is: "KIRIM_BARANG",
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required("No Kirim Barang"),
    }),
    keterangan: Yup.string().nullable(),
    detail:
      productList.length > 0
        ? Yup.string().nullable()
        : Yup.string().required("Detail barang"),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tipeProses: "KIRIM_BARANG",
      noKirim: null,
    },
  });

  const requiredItems = Object.values(errors);

  const handleUpdateTabData = () => {
    const updatedList = [...dataPerTabs];
    updatedList.splice(tabOpen, 1);

    setChoosenData(updatedList);
  };

  useEffect(() => {
    if (requiredItems.length > 0) {
      setOpenError(true);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    $(datePickerRef.current).datepicker({
      onSelect: (dateText) => {
        setValue("tglPemindahan", dateText);
      },
    });
    // eslint-disable-next-line
  }, []);

  // pesanan query
  const { data: purchaseOrderData } = useQuery(PURCHASE_ORDER_LIST);
  // penerimaan query
  const { data: receiveOrderData } = useQuery(RECEIVE_ORDER_LIST);
  // permintaan query
  const { data: requestOrderData } = useQuery(REQUEST_ORDER_LIST);
  const { data: satuanData } = useQuery(SATUAN_LIST, {
    variables: {
      input: {
        nama: "",
      },
    },
  });
  const { data: gudangData } = useQuery(GUDANG_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: dataBarangJasa } = useQuery(GOODS_AND_SERVICE_LIST);
  const [createGoodsTransfer, { loading }] = useMutation(
    CREATE_TRANSFER_GOODS,
    {
      onCompleted: (resp) => {
        toast.success("Pemindahan barang berhasil dibuat");
        setTabOpen(0);
        refetch();
      },
      onError: (resp) => {
        toast.error(resp.message);
      },
      // update(cache, { data }) {
      //   const list = cache.readQuery({
      //     query: CATEGORY_LIST,
      //   });
      //   cache.writeQuery({
      //     query: CATEGORY_LIST,
      //     data: {
      //       barang: {
      //         getKategoriBarang: [
      //           ...list.barang.getKategoriBarang,
      //           data.barang.createKategoriBarang,
      //         ],
      //       },
      //     },
      //   });
      // },
    }
  );

  const [deleteGoodsTransfer] = useMutation(DELETE_TRANSFER_GOODS, {
    onCompleted: (resp) => {
      setTabOpen(0);
      setOpenDelete(false);
      toast.success("Sukses menghapus data");
      setTabs(tabs.filter((tab) => tab.id !== tabOpen));
      handleUpdateTabData();
      refetch();
    },
    onError: (resp) => {
      toast.error(resp);
    },
    // update(cache, { data }) {
    //   const list = cache.readQuery({
    //     query: CATEGORY_LIST,
    //   });
    //   const updatedList = list.barang.getKategoriBarang.filter(
    //     (category) =>
    //       category.kategori_barang_id !== choosenData.kategori_barang_id
    //   );
    //   cache.writeQuery({
    //     query: CATEGORY_LIST,
    //     data: {
    //       barang: {
    //         getKategoriBarang: updatedList,
    //       },
    //     },
    //   });
    // },
  });

  const [ubahGoodsTransfer, { loading: loadingUpdate }] = useMutation(
    UPDATE_TRANSFER_GOODS,
    {
      onCompleted: (resp) => {
        setTabOpen(0);
        toast.success("Sukses mengubah data");
        setTabs(tabs.filter((tab) => tab.id !== tabOpen));
        handleUpdateTabData();
        refetch();
      },
      onError: (resp) => {
        toast.error(resp);
      },
    }
  );

  const dataSatuan = satuanData?.barang.getSatuanBarang.data.map((satuan) => ({
    id: satuan.uom_id,
    name: `${satuan.uom_alias} - ${satuan.uom_name}`,
    alias: satuan.uom_name,
  }));
  const barangJasaList = dataBarangJasa?.barang.findAll.data.map((barang) => ({
    id: barang.kode_barang,
    name: barang.nama_barang,
    ...barang,
  }));
  const dataGudang = gudangData?.Gudang.findAll.map((gudang) => ({
    id: gudang.kode_gudang,
    name: `Gudang ${gudang.nama_gudang}`,
    desc: gudang.alamat,
    kode_cabang: gudang.kode_cabang,
  }));
  const dataKirimBarang = dataGoodsTransfer?.PemindahanBarang.findAll
    ?.filter(
      (barang) =>
        barang.tipeProses === "KIRIM_BARANG" &&
        barang.kodeGudangKe === watch("gudangKe")
    )
    ?.map((barang) => ({
      id: barang.noPemindahan,
      name: barang.noPemindahan,
      desc: `${format(new Date(barang.tglPemindahan), "dd/MM/yyyy")} - ${
        barang.gudangDari
      }`,
      ...barang,
    }));
  const dataPurchaseOrder = purchaseOrderData?.PesananPembelian.findAll.map(
    (row) => ({
      nomor: row.nomor_pesanan,
      gudangPenerima: row.gudang_penerima,
      tanggal: row.tgl_pesanan,
      detailItem: row.detail_item,
    })
  );
  const dataReceiveOrder = receiveOrderData?.PenerimaanBarang.findAll.map(
    (row) => ({
      nomor: row.nomor_penerimaan,
      gudangPenerima: row.gudang_penerima,
      tanggal: row.tgl_terima,
      detailItem: row.detail_item,
    })
  );
  const dataRequestOrder = requestOrderData?.PermintaanBarang.findAll.map(
    (row) => ({
      nomor: row.nomor_permintaan,
      gudangPenerima: row.gudangPenerima,
      tanggal: row.tgl_permintaan,
      detailItem: row.detail,
    })
  );

  useEffect(() => {
    if (detail && choosenData) {
      // const gudangDetailList = cabangList.find(
      //   (gudang) => gudang.id === choosenData.cabang.kode_cabang
      // );
      setValue(
        "tglPemindahan",
        format(new Date(choosenData.tglPemindahan), "MM/dd/yyyy")
      );
      setValue("tipeProses", choosenData.tipeProses);
      setValue("gudangDari", choosenData.kodeGudangDari);
      setValue("gudangKe", choosenData.kodeGudangKe);
      setValue("noKirim", choosenData.noKirim);
      setValue("keterangan", choosenData.keterangan);
      setProductList(
        choosenData.detail.map((product) => ({
          code: product.kodeBarang,
          name: product.namaBarang,
          quantity: product.qty,
          satuan: product.kodeSatuan,
          // remarks: product.keterangan,
          mode: "e",
        }))
      );
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [detail, choosenData]);

  const handleGetSatuanOption = (selectedBarang) => {
    const tempSatuanOption = selectedBarang.detail_harga.map((harga) => ({
      id: harga.uom.uom_id,
      name: `${harga.uom.uom_alias} - ${harga.uom.uom_name}`,
      alias: harga.uom.uom_name,
    }));
    if (
      !tempSatuanOption.find(
        (option) => option.id === selectedBarang.base_uom.uom_id
      )
    ) {
      tempSatuanOption.push({
        id: selectedBarang.base_uom.uom_id,
        name: `${selectedBarang.base_uom.uom_alias} - ${selectedBarang.base_uom.uom_name}`,
        alias: selectedBarang.base_uom.uom_name,
      });
    }
    setSatuanOption(tempSatuanOption);
  };

  const handleOpenDetail = (index) => {
    const choosenProduct = productList[index];
    const selectedBarang = barangJasaList?.find(
      (barang) => barang.id === choosenProduct.code
    );
    handleGetSatuanOption(selectedBarang);
    setProductToAdd({
      code: choosenProduct.code,
      name: choosenProduct.name,
      quantity: choosenProduct.quantity,
      satuan: choosenProduct.satuan,
      tglDiminta: choosenProduct.tglDiminta,
      remarks: choosenProduct.remarks,
      mode: choosenProduct.mode,
    });
    setShow({ ...show, modal: true });
    setEditProduct(true);
    setChoosenId(index);

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}barang/upload/${choosenProduct.code}`
    )
      .then((res) => res.blob())
      .then((data) => setProductPhoto(data));
  };

  const handleAddTableProduct = () => {
    if (editProduct) {
      const temp = productList;
      productList[choosenId] = productToAdd;
      setProductList([...temp]);
    } else {
      setProductList([...productList, productToAdd]);
    }
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      quantity: 0,
      stok: 0,
      satuan: null,
      remarks: "",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
    setEditProduct(false);
  };

  const handleDeleteTableProduct = () => {
    const temp = [...productList];
    temp[choosenId].mode = "d";
    setProductList([...temp]);
    setModalTab(0);
    setProductToAdd({
      code: "",
      name: "",
      quantity: 0,
      satuan: null,
      remarks: "",
      mode: "c",
    });
    setSatuanOption([]);
    setShow({ ...show, modal: false });
    setEditProduct(false);
  };

  const handleSaveRequest = (dataSubmit) => {
    const inputs = {
      tglPemindahan: format(new Date(dataSubmit.tglPemindahan), "yyyy/MM/dd"),
      tipeProses: dataSubmit.tipeProses,
      gudangDari: dataSubmit.gudangDari,
      gudangKe: dataSubmit.gudangKe,
      noKirim: dataSubmit.noKirim,
      keterangan: dataSubmit.keterangan,
      detail: productList.map((product) => ({
        kodeBarang: product.code,
        qty: parseFloat(product.quantity),
        uom: parseFloat(product.satuan),
      })),
    };
    if (detail) {
      ubahGoodsTransfer({
        variables: {
          input: {
            ...inputs,
            id: choosenData.noPemindahan,
          },
        },
      });
    } else {
      createGoodsTransfer({
        variables: {
          input: inputs,
        },
      });
    }
  };

  const confirmDelete = () => {
    deleteGoodsTransfer({
      variables: {
        input: choosenData.noPemindahan,
      },
    });
  };

  const handleKBOptionCheckbox = (value, i) => {
    let temp = kirimBarangOption[i];
    temp.selected = value;
    kirimBarangOption[i] = temp;
    setKirimBarangOption([...kirimBarangOption]);
  };

  const handleAmbilBarangOption = () => {
    if (watch("tipeProses") === "KIRIM_BARANG") {
      if (watch("gudangDari")) {
        setShow({ ...show, ambil: true });
        const purchaseAndReceiveOrder = dataPurchaseOrder.concat(
          dataReceiveOrder.concat(dataRequestOrder)
        );

        const allData = purchaseAndReceiveOrder
          .filter(
            (row) => row.gudangPenerima.kode_gudang === watch("gudangDari")
          )
          .map((row) => ({
            id: row.nomor,
            name: row.nomor,
            desc: `${format(new Date(row.tanggal), "dd/MM/yyyy")}`,
            detail: row.detailItem,
          }));

        setAmbilBarangOption(allData);
      } else {
        toast.error("Pilih salah satu gudang asal untuk melanjutkan");
      }
    } else {
      if (watch("gudangKe")) {
        setShow({ ...show, ambil: !show.ambil });
      } else {
        toast.error("Pilih salah satu gudang tujuan untuk melanjutkan");
      }
    }
  };

  const handleAmbilKB = () => {
    if (kirimBarangOption.length > 0) {
      if (watch("tipeProses") === "KIRIM_BARANG") {
        const products = productList.concat(
          kirimBarangOption
            .filter((htg) => htg.selected)
            .map((htg) => ({
              code: htg.code,
              name: htg.name,
              quantity: htg.quantity,
              // stok: htg.stok,
              satuan: htg.satuan,
              remarks: htg.remarks,
              mode: "c",
            }))
        );
        setProductList(products);
        setShow({
          ...show,
          ambil: false,
        });
        setKirimBarangOption([]);
      } else {
        const choosenReceive = dataKirimBarang.find(
          (purchaseOrder) => purchaseOrder.id === watch("noKirim")
        );
        setValue("gudangDari", choosenReceive.kodeGudangDari);
        setValue("gudangKe", choosenReceive.kodeGudangKe);
        setValue(
          "tglPemindahan",
          format(new Date(choosenReceive.tglPemindahan), "MM/dd/yyyy")
        );
        const products = productList.concat(
          kirimBarangOption
            .filter((htg) => htg.selected)
            .map((htg) => ({
              code: htg.code,
              name: htg.name,
              quantity: htg.quantity,
              // stok: htg.stok,
              satuan: htg.satuan,
              remarks: htg.remarks,
              mode: "c",
            }))
        );
        setProductList(products);
        setShow({
          ...show,
          ambil: false,
        });
        setKirimBarangOption([]);
      }
    } else {
      toast.error("Pilih salah satu nomor kirim barang untuk melanjutkan");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleSaveRequest)}>
        <div className="left-content-module">
          <div className="content-nav-module">
            <form id="" className="">
              <div className="form-wrap">
                <div className={`form-container ${detail && "disable"}`}>
                  <div className="title">Tanggal</div>
                  <input
                    id="datepicker_from"
                    ref={datePickerRef}
                    className="form-date-input"
                    type="text"
                    placeholder="Pilh tanggal"
                    value={watch("tglPemindahan")}
                    onChange={(e) => setValue("tglPemindahan", e.target.value)}
                  />
                  <div className="icon-form-date icon-date_ic"></div>
                </div>
              </div>

              {/* <!--TIPE PERMINTAAN--> */}
              <div className="form-wrap">
                <DropdownInput
                  disabled={detail}
                  title="Tipe Permintaan"
                  setOpen={(e) => setShowType(e)}
                >
                  {showType && (
                    <div className="popup-wrap gudang-wrap">
                      <ul>
                        {tipeProsesOption.map((row, i) => (
                          <li key={i}>
                            <div
                              className="text-list"
                              onClick={() => {
                                setValue("tipeProses", row.id);
                                setShowType(false);
                                setProductList([]);
                                setValue("gudangDari", "");
                                setValue("gudangKe", "");
                                setValue("noKirim", null);
                                // setType(parseInt(row.id.slice(-1)));
                              }}
                            >
                              {row.label}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <input
                    name=""
                    type="text"
                    placeholder="Pilih Jenis Barang"
                    value={
                      tipeProsesOption.find(
                        (tipe) => tipe.id === watch("tipeProses")
                      ).label
                    }
                    onClick={() => setShowType(!showType)}
                  />
                </DropdownInput>
              </div>

              {watch("tipeProses") === "KIRIM_BARANG" && (
                <div className="form-wrap">
                  <SearchInput
                    title="Gudang Asal"
                    placeholder="Cari/Pilih Gudang"
                    value={watch("gudangDari")}
                    setValue={(e) => {
                      setValue("gudangDari", e);
                    }}
                    searchData={dataGudang}
                    disabled={detail}
                  />
                </div>
              )}

              <div className="form-wrap">
                <SearchInput
                  title="Gudang Tujuan"
                  placeholder="Cari/Pilih Gudang"
                  value={watch("gudangKe")}
                  setValue={(e) => {
                    setValue("gudangKe", e);
                  }}
                  searchData={dataGudang}
                  disabled={detail}
                />
              </div>

              <div className="form-right-side">
                {/* <!--RIGHT SIDE--> */}
                <div className="form-wrap">
                  <div className="form-container">
                    <div className="title">Nomor#</div>
                    <div className="auto-form-container disable w-fluid">
                      <input
                        name=""
                        type="text"
                        placeholder="Cari gudang"
                        value={choosenData?.noPemindahan || "-"}
                      />
                    </div>
                  </div>
                </div>

                {!detail && (
                  <div className="nav-setting-wrap">
                    <div className="list-setting">
                      <div
                        className="setting-btn more-btn"
                        onClick={handleAmbilBarangOption}
                      >
                        Ambil
                      </div>
                    </div>
                  </div>
                )}

                {show.ambil && (
                  <div className="window-overlay">
                    <div className="window-container">
                      <div className="window-nav">
                        <div className="title flex-center">
                          {watch("tipeProses") === "KIRIM_BARANG"
                            ? "Salin dari PO/RI/PR"
                            : "Salin dari Pengiriman Barang"}
                        </div>
                        <div className="close-btn flex-center">
                          <span
                            className="icon icon-close_ic"
                            onClick={() => {
                              setShow({
                                ...show,
                                ambil: false,
                              });
                              setKirimBarangOption([]);
                              setAmbilBarangOption([]);
                              setValue("noKirim", null);
                            }}
                          ></span>
                        </div>
                      </div>
                      <div className="window-content">
                        <form id="" className="">
                          <div className="form-wrap no-float">
                            <SearchInput
                              title={
                                watch("tipeProses") === "KIRIM_BARANG"
                                  ? "PO/RI/PR"
                                  : "Kirim Barang"
                              }
                              placeholder={
                                watch("tipeProses") === "KIRIM_BARANG"
                                  ? "Cari/Pilih Nomor"
                                  : "Cari/Pilih Kirim Barang"
                              }
                              setValue={(e) => {
                                if (watch("tipeProses") === "KIRIM_BARANG") {
                                  const choosenReceive = ambilBarangOption.find(
                                    (row) => row.id === e
                                  );
                                  const selectedDetail = choosenReceive.detail;
                                  const detailBarang = selectedDetail.map(
                                    (detail) => ({
                                      selected: false,
                                      code: detail.barang.kode_barang,
                                      name: detail.barang.nama_barang,
                                      quantity: detail.qty,
                                      // stok: detail.qty_proses,
                                      satuan: detail.satuan.uom_id,
                                      remarks: detail.keterangan || "",
                                      mode: "c",
                                    })
                                  );
                                  setKirimBarangOption(detailBarang);
                                } else {
                                  const choosenReceive = dataKirimBarang.find(
                                    (purchaseOrder) => purchaseOrder.id === e
                                  );
                                  const selectedDetail = choosenReceive.detail;
                                  setValue("noKirim", e);
                                  const detailBarang = selectedDetail.map(
                                    (detail) => ({
                                      selected: false,
                                      code: detail.kodeBarang,
                                      name: detail.namaBarang,
                                      quantity: detail.qty,
                                      // stok: detail.qty_proses,
                                      satuan: detail.kodeSatuan,
                                      remarks: detail.keterangan || "",
                                      mode: "c",
                                    })
                                  );
                                  setKirimBarangOption(detailBarang);
                                }
                              }}
                              searchData={
                                watch("tipeProses") === "KIRIM_BARANG"
                                  ? ambilBarangOption
                                  : dataKirimBarang
                              }
                            />
                            <div className="clear"></div>
                          </div>
                        </form>

                        <div className="main-content-module">
                          <div className="">
                            <table id="all-data-table" className="detail-page">
                              <thead>
                                <tr>
                                  <th>
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={
                                          kirimBarangOption?.filter(
                                            (request) => request.selected
                                          ).length > 0
                                        }
                                        onClick={() => {
                                          let temp = kirimBarangOption;
                                          let a = temp.map((request) => ({
                                            ...request,
                                            selected: !request.selected,
                                          }));
                                          setKirimBarangOption([...a]);
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </th>
                                  <th>Kode #</th>
                                  <th>Nama Barang</th>
                                  <th>Kuantitas</th>
                                  <th>Satuan</th>
                                </tr>
                              </thead>
                              <tbody>
                                {kirimBarangOption?.map((request, i) => (
                                  <tr>
                                    <td>
                                      <label className="checkbox">
                                        <input
                                          type="checkbox"
                                          checked={request.selected}
                                          onClick={() =>
                                            handleKBOptionCheckbox(
                                              !request.selected,
                                              i
                                            )
                                          }
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>{request.code}</td>
                                    <td>{request.name}</td>
                                    <td>{request.quantity}</td>
                                    <td>
                                      {dataSatuan?.find(
                                        (satuan) => satuan.id === request.satuan
                                      )?.name || request.satuan}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="window-bottom">
                        <div
                          className="button btn-blue flex-center"
                          onClick={handleAmbilKB}
                        >
                          Lanjutkan
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* {
                  <div class="window-overlay">
                    <div class="window-container product-window">
                      <div class="window-nav">
                        <div class="tab_content_module">
                          <div class="tab_menu_content active">
                            Rincian Barang
                          </div>
                          <div class="tab_menu_content">Info Lainnya</div>
                          <div class="tab_menu_content">Bahan Alternatif</div>
                        </div>

                        <div class="close-btn flex-center">
                          <span class="icon icon-close_ic"></span>
                        </div>
                      </div>

                      <div class="window-content">
                        <div class="product-img">
                          <img src="img/product.jpg" />
                        </div>
                        <div class="form-side">
                          <form id="" class="">
                            <div class="form-wrap">
                              <div class="form-container lg">
                                <div class="title">Kode#</div>
                                <div class="bold">1234567</div>
                              </div>

                              <div class="form-container lg">
                                <div class="title">Nama Barang</div>

                                <div class="auto-form-container disable">
                                  <input
                                    name=""
                                    type="text"
                                    placeholder="Cari gudang"
                                    value="ABON SAPI"
                                  />
                                </div>
                              </div>

                              <div class="form-container md">
                                <div class="title">Kuantitas diminta</div>
                                <div class="auto-form-container disable">
                                  <input
                                    name=""
                                    type="text"
                                    placeholder="Cari gudang"
                                    value="10"
                                  />
                                </div>
                                <div class="icon-form-date icon-penjualan_ic"></div>
                              </div>

                              <div class="form-container sm padding-l">
                                <div class="title">Satuan</div>
                                <div class="auto-form-container disable">
                                  <ul class="input-label">
                                    <li>
                                      <label>PCS</label>{" "}
                                      <span>
                                        <i class="icon icon-close_ic"></i>
                                      </span>
                                    </li>
                                  </ul>

                                  <input
                                    name=""
                                    type="text"
                                    placeholder="Cari gudang"
                                    value=""
                                  />
                                </div>
                                <div class="icon-form-date icon-search_ic"></div>
                              </div>

                              <div class="form-container lg">
                                <div class="title">Kuatitas dipenuhi</div>

                                <div class="auto-form-container">
                                  <input
                                    name=""
                                    type="text"
                                    placeholder="Cari gudang"
                                    value="100"
                                  />
                                </div>
                              </div>

                              <div class="form-container lg">
                                <div class="title">Gudang</div>
                                <div class="auto-form-container">
                                  <ul class="input-label">
                                    <li>
                                      <label>Anita Surabaya</label>{" "}
                                      <span>
                                        <i class="icon icon-close_ic"></i>
                                      </span>
                                    </li>
                                  </ul>

                                  <input
                                    name=""
                                    type="text"
                                    placeholder="Cari gudang"
                                    value=""
                                  />
                                </div>
                                <div class="icon-form-date icon-search_ic"></div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="clear"></div>
                      </div>

                      <div class="window-content">
                        <form id="" class="">
                          <div class="form-wrap w-fluid">
                            <div class="form-container w-fluid">
                              <div class="title">Catatan</div>

                              <div class="auto-form-container w-fluid">
                                <textarea
                                  id=""
                                  name=""
                                  rows="4"
                                  cols="50"
                                  placeholder="Tulisakan catatan di sini"
                                ></textarea>
                              </div>
                            </div>
                            <div class="form-container w-fluid">
                              <div class="title">No Permintaan</div>
                              <div class="auto-form-container disable green-f w-fluid">
                                <input
                                  name=""
                                  type="text"
                                  placeholder="Cari gudang"
                                  value="PR/DARMO/TART/211122/001"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                        <div class="clear"></div>
                      </div>

                      <div class="window-content">
                        <div class="col-fulltab-50">
                          <div class="alternate-wrap">
                            <div class="alternate-list">
                              <div class="title">Tepung Segitiga Biru</div>
                              <label class="alternate-item">
                                <span class="option-value">
                                  Tepung Kotak Biru
                                </span>
                                <input type="radio" name="tepung" value="1" />
                              </label>
                              <label class="alternate-item">
                                <span class="option-value">
                                  Tepung Bulat Biru
                                </span>
                                <input type="radio" name="tepung" value="2" />
                              </label>
                            </div>
                          </div>
                          <div class="alternate-wrap">
                            <div class="alternate-list">
                              <div class="title">Minyak Goreng ABC</div>
                              <label class="alternate-item">
                                <span class="option-value">
                                  Minyak Goreng XYZ
                                </span>
                                <input type="radio" name="minyak" value="1" />
                              </label>
                              <label class="alternate-item">
                                <span class="option-value">
                                  Minyak Goreng DEF
                                </span>
                                <input type="radio" name="minyak" value="2" />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="col-fulltab-50">
                          <div class="alternate-wrap">
                            <div class="alternate-list">
                              <div class="title">Sasa Penyedap</div>
                              <label class="alternate-item">
                                <span class="option-value">Masako</span>
                                <input type="radio" name="micin" value="1" />
                              </label>
                              <label class="alternate-item">
                                <span class="option-value">Micin Jamur</span>
                                <input type="radio" name="micin" value="2" />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="clear"></div>
                      </div>

                      <div class="window-bottom">
                        <div class="button btn-blue flex-center">Lanjutkan</div>
                      </div>
                    </div>
                  </div>
                } */}
              </div>

              <div className="clear"></div>
            </form>
          </div>
          <div className="main-content-module detail-page">
            <div className="detail-data-wrap">
              <div className="wrap">
                <div
                  className={`tab-menu ${tab === 0 && "active"}`}
                  onClick={() => setTab(0)}
                >
                  <span className="icon-input_ic"></span>
                </div>
                <div
                  className={`tab-menu ${tab === 1 && "active"}`}
                  onClick={() => setTab(1)}
                >
                  <span className="icon-info_ic"></span>
                </div>
                {detail && watch("tipeProses") === "KIRIM_BARANG" && (
                  <div
                    className={`tab-menu ${tab === 2 && "active"}`}
                    onClick={() => setTab(2)}
                  >
                    <span className="icon-doc_ic"></span>
                  </div>
                )}
              </div>

              {tab === 0 && (
                <div className="content-wrap">
                  <div className="content-nav-module">
                    <form id="" className="">
                      {watch("tipeProses") === "KIRIM_BARANG" ? (
                        <div className="form-wrap">
                          <SearchInput
                            disabled={detail}
                            title="Barang &amp; Jasa"
                            placeholder="Cari/Pilih Barang & Jasa"
                            setValue={(e) => {
                              const selectedBarang = barangJasaList?.find(
                                (barang) => barang.id === e
                              );
                              handleGetSatuanOption(selectedBarang);
                              setProductToAdd({
                                ...productToAdd,
                                code: selectedBarang.id,
                                name: selectedBarang.name,
                                satuan: selectedBarang.base_uom.uom_id,
                              });
                              setShow({ ...show, modal: true });
                              fetch(
                                `${process.env.REACT_APP_BACKEND_URL}barang/upload/${selectedBarang.id}`
                              )
                                .then((res) => res.blob())
                                .then((data) => setProductPhoto(data));
                            }}
                            searchData={barangJasaList}
                          />
                        </div>
                      ) : (
                        watch("noKirim") !== "-" && (
                          <div className="form-wrap">
                            <div className="form-container">
                              <div className="title">Nomor Kirim Barang</div>

                              <div className="auto-form-container">
                                {watch("noKirim") !== null && (
                                  <ul className="input-label">
                                    <li>
                                      <label>{watch("noKirim")}</label>
                                    </li>
                                  </ul>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      <div className="clear"></div>
                    </form>
                  </div>
                  <div className="main-content-module">
                    <table id="all-data-table" className="detail-page m-b-30">
                      <tr>
                        <th>Nama Barang</th>
                        <th>Kode #</th>
                        <th>Kuantitas</th>
                        <th>Satuan</th>
                        {/* {detail && (
                          <>
                            <th>Kts Diterima</th>
                            <th>Kts Tidak Diterima</th>
                          </>
                        )} */}
                      </tr>
                      {productList
                        .filter((product) => product.mode !== "d")
                        .map((row, i) => (
                          <tr key={i} onClick={() => handleOpenDetail(i)}>
                            <td>{row.name}</td>
                            <td>{row.code}</td>
                            <td className="txt-right">{row.quantity}</td>
                            <td className="txt-right">
                              {dataSatuan?.find(
                                (satuan) => satuan.id === row.satuan
                              )?.name || row.satuan}
                            </td>
                            {/* {detail && (
                              <>
                                <td className="txt-right">{row.quantity}</td>
                                <td className="txt-right">{row.quantity}</td>
                              </>
                            )} */}
                          </tr>
                        ))}
                    </table>
                  </div>

                  {show.modal && (
                    <>
                      {/* <!--POPUP PRODUCT --> */}
                      <div className="window-overlay">
                        <div className="window-container product-window">
                          <div className="window-nav">
                            {/* <!--CONTENT TAB--> */}
                            <div className="tab_content_module">
                              {["Rincian Barang", "Info Lain"].map((tab, i) => (
                                <div
                                  className={`tab_menu_content ${
                                    modalTab === i && "active"
                                  }`}
                                  onClick={() => setModalTab(i)}
                                >
                                  {tab}
                                </div>
                              ))}
                            </div>
                            <div
                              className="close-btn flex-center"
                              onClick={() => {
                                setShow({ ...show, modal: false });
                                setProductToAdd({
                                  code: "",
                                  name: "",
                                  quantity: 0,
                                  satuan: null,
                                  remarks: "",
                                  mode: "c",
                                });
                                setEditProduct(false);
                              }}
                            >
                              <span className="icon icon-close_ic"></span>
                            </div>
                          </div>
                          <div className="window-content">
                            {modalTab === 0 && (
                              <>
                                <div className="product-img">
                                  {productPhoto !== null && (
                                    <img
                                      src={URL.createObjectURL(productPhoto)}
                                      alt={`${productToAdd.code} - ${productToAdd.name}`}
                                    />
                                  )}
                                </div>

                                <div className="form-side">
                                  <form id="" className="">
                                    <div className="form-wrap">
                                      <div className="form-container lg">
                                        <p
                                          className="text-desc"
                                          style={{ fontSize: "11px" }}
                                        >
                                          Kode #
                                        </p>
                                        <h3 className="text-list">
                                          {productToAdd.code}
                                        </h3>
                                      </div>

                                      <div className="form-container lg">
                                        <div className="title">Nama</div>
                                        <div className="auto-form-container">
                                          <input
                                            type="text"
                                            placeholder="Nama Barang"
                                            value={productToAdd.name}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                name: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-container md">
                                        <div className="title">Kuantitas</div>
                                        <div className="auto-form-container">
                                          <input
                                            name=""
                                            type="text"
                                            placeholder="Cari gudang"
                                            value={productToAdd.quantity}
                                            onChange={(e) =>
                                              setProductToAdd({
                                                ...productToAdd,
                                                quantity: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                        <div className="icon-form-date icon-penjualan_ic"></div>
                                      </div>

                                      <SearchInput
                                        className="sm padding-l"
                                        title="Satuan"
                                        placeholder="Cari satuan"
                                        value={productToAdd.satuan}
                                        setValue={(e) =>
                                          setProductToAdd({
                                            ...productToAdd,
                                            satuan: e,
                                          })
                                        }
                                        searchData={satuanOption}
                                      />

                                      {/* {watch("tipeProses") ===
                                        "KIRIM_BARANG" && (
                                        <div className="form-container lg">
                                          <div className="title">
                                            Stok Gudang
                                          </div>
                                          <div className="auto-form-container">
                                            <input
                                              type="text"
                                              placeholder="Nama Barang"
                                              value={productToAdd.stok}
                                              onChange={(e) =>
                                                setProductToAdd({
                                                  ...productToAdd,
                                                  stok: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      )} */}
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}

                            {modalTab === 1 && (
                              <div className="content-wrap">
                                <div className="content-nav-module">
                                  <form id="" className="">
                                    <div className="col-fulltab-50">
                                      <div className="form-wrap">
                                        <div className="form-container">
                                          <div className="auto-form-container w-400px">
                                            <textarea
                                              id=""
                                              name=""
                                              rows="4"
                                              cols="50"
                                              value={productToAdd.remarks}
                                              onChange={(e) =>
                                                setProductToAdd({
                                                  ...productToAdd,
                                                  remarks: e.target.value,
                                                })
                                              }
                                              placeholder="Tuliskan keterangan di sini"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="window-bottom">
                            <div
                              className="button btn-blue flex-center"
                              onClick={handleAddTableProduct}
                            >
                              Simpan
                            </div>
                            {editProduct && !detail && (
                              <div
                                className="button btn-red flex-center"
                                onClick={handleDeleteTableProduct}
                              >
                                Hapus
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {tab === 1 && (
                <div className="content-wrap">
                  <div className="content-nav-module">
                    <form id="" className="">
                      <div className="section-title">Info Lainnya</div>
                      <div className="col-fulltab-50">
                        <div className="form-wrap">
                          <div className="form-container">
                            <div className="title">Catatan</div>

                            <div className="auto-form-container w-400px">
                              <textarea
                                id=""
                                name=""
                                rows="4"
                                cols="50"
                                placeholder="Tuliskan catatan di sini"
                                {...register("keterangan")}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {tab === 2 && (
                <div className="content-wrap">
                  <div className="content-nav-module full-height">
                    <form id="" className="">
                      <div className="col-fulltab-50">
                        <div className="col-content">
                          <div className="section-title">
                            Ringkasan Pengiriman
                          </div>
                          <div className="row">
                            <div className="label">Penerimaan Barang</div>
                            <div className="label bold txt-right">
                              {choosenData?.listTerima.length > 0
                                ? `${choosenData?.listTerima.length}`
                                : "Belum Ada"}{" "}
                              Data
                            </div>
                          </div>
                          <div className="row">
                            <div className="label">Status</div>
                            <div className="label txt-right float-r">
                              <Status
                                isPaid={choosenData?.status}
                                isFloatRight
                              />
                            </div>
                          </div>
                        </div>
                        {choosenData?.listTerima.map((row) => (
                          <div className="col-content m-t-30">
                            <div
                              className="action green"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleRedirectDetail(row);
                                setTab(0);
                              }}
                            >
                              <div className="row">
                                <div className="label">Terima Barang</div>
                                <div className="label bold txt-right">
                                  {row}
                                </div>
                                <div className="label txt-right">&gt;</div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="col-fulltab-50">
                        <div className="col-content">
                          <div className="section-title">Diproses Oleh</div>
                          <div className="permintaan-log">
                            <div className="number bold">PO.2023.10.00001</div>
                            <div className="date">04/10/2023</div>
                          </div>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <!--CONTENT RIGHT--> */}
        <div className="right-content-module">
          <div className="data-action-wrap">
            <div
              className={`data-action-button green-bg ${
                (loading || loadingUpdate) && "disabled"
              }`}
              onClick={handleSubmit(handleSaveRequest)}
            >
              <span className="icon icon-simpan_ic"></span>
              Simpan
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-cetaik_ic"></span>
              Cetak
            </div>
            <div className="data-action-button purple-bg">
              <span className="icon icon-attach_ic"></span>
              Lampiran
            </div>
            <div className="data-action-button blue-bg">
              <span className="icon icon-more_ic"></span>
              Lainnya
            </div>
            {detail && (
              <div
                className="data-action-button red-bg"
                onClick={() => setOpenDelete(true)}
              >
                <span className="icon icon-hapus_ic"></span>
                Hapus
              </div>
            )}
          </div>
        </div>
        <div className="clear"></div>

        <ModalConfirm show={openDelete}>
          <ModalHeader onClose={() => setOpenDelete(false)}></ModalHeader>
          <ModalBody>
            <p>
              Apakah Anda yakin akan melakukan penghapusan data: Pemndahan
              barang {choosenData?.noPemindahan}?
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="button btn-red flex-center" onClick={confirmDelete}>
              Hapus
            </div>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenDelete(false)}
            >
              Batal
            </div>
          </ModalFooter>
        </ModalConfirm>

        <ModalConfirm show={openError}>
          <ModalHeader onClose={() => setOpenError(false)}></ModalHeader>
          <ModalBody>
            <p>
              Masih memerlukan inputan ini :
              <ul>
                {requiredItems.map((te) => (
                  <li>{te.message}</li>
                ))}
              </ul>
            </p>
          </ModalBody>
          <ModalFooter>
            <div
              className="button btn-transparent flex-center"
              onClick={() => setOpenError(false)}
            >
              Tutup
            </div>
          </ModalFooter>
        </ModalConfirm>
      </form>
    </div>
  );
};

export default AddNewGoodsTransfer;
